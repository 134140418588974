html,
body {
  min-height: 100vh;
  background-color: azure;
  background-image: url('img/bg.jpg');
}

.banner {
  height: 15vh;
}

.footer {
  position: absolute;
  bottom: 0.5vh;
  left: 1vw;
  width: 98vw;
  border-width: 0.1rem;
  border-style: solid;
  border-color: lightgrey;
  border-radius: 5px;
}

.paper {
  height: 80vh;
  opacity: 90%;
}
